// src/components/PlanCard.tsx
import React from 'react';
import Button from '../atoms/button'; // Assuming Button component is defined in a separate file
import Icon from '../atoms/icon';
import Heading from '../atoms/heading'; 
import Paragraph from '../atoms/paragraph';

interface PlanCardProps {
  title: string;
  subtitle: string;
  price: number;
  priceUnit: string;
  features: { text: string; included: boolean }[];
  badgeText?: string;
  isPopular?: boolean;
  iconClass: string;
}

const PlanCard: React.FC<PlanCardProps> = ({ title, subtitle, price, priceUnit, features, badgeText, isPopular, iconClass }) => {
  return (
    <div className={`plan-box mb-0 ${isPopular ? 'ribbon-box right' : ''}`}>
      {isPopular && <div className="ribbon-two ribbon-two-danger"><span>Popular</span></div>}
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          <Heading level={5} className='mb-1 fw-bold'>{title}</Heading>
          <Paragraph className="text-muted mb-0">{subtitle}</Paragraph>
        </div>
       < Icon iconClass={iconClass} small = {false} />
      </div>
      <div className="py-4 text-center">
        <Heading level={1} className={priceUnit === '/Month' ? 'month' : 'annual'}>
          <sup><small>$</small></sup>
          <span className="ff-secondary fw-bold">{price}</span>
          <span className="fs-13 text-muted">{priceUnit}</span>
        </Heading>
      </div>
      <div>
        <ul className="list-unstyled vstack gap-3">
          {features.map((feature, index) => (
            
           
            <li key={index}>
               <div className="d-flex" > 
                <div className={`flex-shrink-0 me-2 ${feature.included ? 'text-success' : 'text-danger'}`}>
                  <Icon iconClass={feature.included ? 'ri-checkbox-circle-fill fs-15 align-middle' : 'ri-close-circle-fill fs-15 align-middle'} small={true} />
                </div>
                <div className="flex-grow-1">
                  {feature.text}
                </div>
              </div> 
            </li>
            
            
          ))}
        </ul>
        <div className="mt-4">
          <Button variant='success' className="soft-success w-100">Get Started</Button>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
