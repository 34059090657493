import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import serviceData from '../config/serviceData.json'; // Adjust the path to your config file

interface Service {
  iconClass: string;
  title: string;
  description: string;
  link: string;
}

interface ServicesState {
  services: Service[];
}

const initialState: ServicesState = {
  services: serviceData
};

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setServices: (state, action: PayloadAction<Service[]>) => {
      state.services = action.payload;
    }
  }
});

export const { setServices } = servicesSlice.actions;
export default servicesSlice.reducer;
