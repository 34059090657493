import React,  { useState }  from 'react';
import { Col, Row, Form, Button } from 'reactstrap';
import InputField from '../atoms/inputField';
import Captcha from '../atoms/captcha'; // Import the Captcha atom
import axios from 'axios';


const onChange = (value: string | null) => {
  console.log("Captcha value:", value);
};

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    organisationname: '',
    mobileno: '',
    //subject: '',
    comments: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await axios.post('http://localhost:5000/api/send', formData);
      alert('Message sent successfully!');
      setFormData({
        name: '',
        email: '',
        organisationname: '',
        mobileno: '',
      //  subject: '',
        comments: ''
      });
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message.');
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col lg={6}>
          <InputField label="Name" id="name" type="text" name="name" placeholder="Your name*" value={formData.name} onChange={handleChange} />
        </Col>
        <Col lg={6}>
          <InputField label="Email" id="email" type="email" name="email" placeholder="Your email*" value={formData.email} onChange={handleChange} />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <InputField label="Organisation Name*" id="organisationname" type="text" name="organisationname" placeholder="Organisation Name*" value={formData.organisationname} onChange={handleChange} />
        </Col>
        <Col lg={6}>
          <InputField label="Mobile No.*" id="mobile" type="tel" name="mobileno" placeholder="Your Mobile No.*" value={formData.mobileno} onChange={handleChange} />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          {/* <div className="mb-4">
            <label htmlFor="subject" className="form-label fs-13">Subject</label>
            <input type="text" className="form-control bg-light border-light" id="subject" name="subject" placeholder="Your Subject.." value={formData.subject} onChange={handleChange}/>
          </div> */}
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="mb-3">
            <label htmlFor="comments" className="form-label fs-13">Message</label>
            <textarea name="comments" id="comments" rows={3} className="form-control bg-light border-light" placeholder="Your message..." value={formData.comments} onChange={handleChange}></textarea>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Captcha
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="text-end">
        <Button type="submit" className="submitBnt btn btn-primary">Send Message</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactForm;
