// Navbar.tsx
import React, { useState, useEffect } from "react";
import { Container, NavbarToggler, Collapse } from "reactstrap";
import NavbarBrand from "../molecules/navbrand"; // Adjust path as per your project structure
import NavLinks from "../molecules/navLinks";
import Slider from "../molecules/slider";
import Button from "../atoms/button"; // Assuming you have Button component

interface NavbarProps {
  onNavLinkClick: (href: string) => void;
  activeLink: string;
}

const Navbar: React.FC<NavbarProps> = ({ onNavLinkClick, activeLink }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [navClass, setNavClass] = useState("");

  const toggle = () => setIsOpenMenu(!isOpenMenu);

  const handleNavLinkClick = (href: string) => {
    onNavLinkClick(href);
    const element = document.querySelector(href);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const scrollNavigation = () => {
      const scrollUp = document.documentElement.scrollTop;
      if (scrollUp > 50) {
        setNavClass("is-sticky");
      } else {
        setNavClass("");
      }
    };

    window.addEventListener("scroll", scrollNavigation);
    return () => {
      window.removeEventListener("scroll", scrollNavigation);
    };
  }, []);

  const handleClick = () => {
    console.log("Button clicked");
  };

  return (
    <nav
      className={"navbar navbar-expand-lg navbar-landing fixed-top " + navClass}
      id="navbar"
    >
      <Container>
        <NavbarBrand />
        <NavbarToggler
          className="navbar-toggler py-0 fs-20 text-body"
          onClick={toggle}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded={isOpenMenu}
          aria-label="Toggle navigation"
        >
          <i className="mdi mdi-menu"></i>
        </NavbarToggler>
        <Collapse
          isOpen={isOpenMenu}
          className="navbar-collapse"
          id="navbarSupportedContent"
        >
          <NavLinks
            onNavLinkClick={handleNavLinkClick}
            activeLink={activeLink}
          />
          <div className="">
            <Button
               customClassName="fw-medium text-decoration-none text-body mx-2"
              onClick={handleClick}
              variant="link"
              
             
            >
              Sign In
            </Button>

            <Button
              
              onClick={handleClick}
              variant="primary"
            >
              Sign up
            </Button>
          </div>
        </Collapse>
        
      </Container>
    </nav>
  );
};

export default Navbar;
