import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Heading from '../atoms/heading';
import Button from '../atoms/button';

const FeatureHighlight: React.FC = () => {
  return (
    <section className="py-5 bg-primary position-relative">
      <div className="bg-overlay bg-overlay-pattern opacity-50"></div>
      <Container>
        <Row className="align-items-center gy-4">
          <Col className="col-sm">
          <div>
            <Heading level={4} className="text-white mb-0 fw-semibold">
              Build your web App/SaaS with Velzon dashboard
            </Heading>
            </div>
          </Col>
          
          <Col className="col-sm-auto">
            <Button to="/1.envato.market/velzon-admin" className="btn bg-gradient btn-danger">
              <i className="ri-shopping-cart-2-line align-middle me-1"></i> Buy Now
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FeatureHighlight;
