import React from 'react';

interface SwitchProps {
    id: string;
    onChange: () => void;
}

const Switch: React.FC<SwitchProps> = ({ id, onChange }) => {
    return (
        <div className="form-check form-switch fs-20 ms-3" onClick={onChange}>
            <input className="form-check-input" type="checkbox" id={id} />
            <label className="form-check-label" htmlFor={id}></label>
        </div>
    );
};

export default Switch;
