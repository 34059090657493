import React from 'react';
import { Image as BootstrapImage, ImageProps } from 'react-bootstrap';

interface LogoProps extends ImageProps {
  customClassName?: string; // Additional custom CSS classes
}

const Logo: React.FC<LogoProps> = ({ customClassName, ...props }) => {
  return <BootstrapImage {...props} className={customClassName} />;
};

export default Logo;
