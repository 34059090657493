// src/components/molecules/FeatureCard.tsx
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Heading from '../atoms/heading';

interface FeatureCardProps {
  imgSrc: string;
  imgAlt: string;
  title: string;
  description: string;
  iconClass: string;
  statistics?: {
    dashboards: number;
    pages: string;
    functionalApps: string;
  }
}

const FeatureCard: React.FC<FeatureCardProps> = ({ imgSrc, imgAlt, title, description, iconClass, statistics }) => {
  return (
   
        <Container>
    <Row className="align-items-center gy-4">
      <Col lg={6} sm={7} className="mx-auto">
        <div>
          <img src={imgSrc} alt={imgAlt} className="img-fluid mx-auto" />
        </div>
      </Col>
      <Col lg={6}>
        <div className="text-muted">
          <div className="avatar-sm icon-effect mb-4">
            <div className={`avatar-title bg-transparent rounded-circle text-success h1 ${iconClass}`} />
          </div>
          <h3 className="mb-3 fs-20">{title}</h3>
          <p className="mb-4 fs-16">{description}</p>
          {statistics && (
                <Row className="pt-3">
                  <Col className="col-3">
                    <div className="text-center">
                    <Heading level={4} >
                        {statistics.dashboards}
                  </Heading>
                      <p>Dashboards</p>
                    </div>
                  </Col>
                  <Col className="col-3">
                    <div className="text-center">
                    <Heading level={4} >
                        {statistics.pages}
                  </Heading>
                      <p>Pages</p>
                    </div>
                  </Col>
                  <Col className="col-4">
                    <div className="text-center">
                    <Heading level={4} >
                        {statistics.functionalApps}
                  </Heading>
                      <p>Functional Apps</p>
                    </div>
                  </Col>
                </Row>
              )}
        </div>
      </Col>
    </Row>
    </Container>
    
  );
};

export default FeatureCard;
