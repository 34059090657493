import React from "react";
import FooterSection from "../molecules/footerSection";
import { Container } from "reactstrap";


interface FooterSectionProps {
    title: string;
    addressHeading: string;
    addressDetails: string;
    heading: string;
    description3: string;
    description1: string;
    description2: string;
    links: { to: string; text: string }[];
    iconLinks: { iconClass: string; linkTo: string; text: string }[];
}

const Footer: React.FC<FooterSectionProps> = ({ title, heading, links, description1, description2,description3, iconLinks, addressHeading, addressDetails }) => {
  
  return (
    
    <footer className="custom-footer bg-dark py-5 position-relative">
     
    <Container>
    <FooterSection
      title={title}
      addressHeading={addressHeading}
      addressDetails={addressDetails}
      heading={heading}
      description1={description1}
      description2={description2}
      description3 = {description3}
      links={links}
      iconLinks={iconLinks}
    />
    </Container>
    </footer>
  );
};

export default Footer;
