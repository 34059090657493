import React from 'react';
import { Link } from 'react-router-dom';

interface IconProps {
  iconClass: string;
  linkTo: string;
  text: string;
  style?: React.CSSProperties;
}

const IconLink: React.FC<IconProps> = ({ iconClass, linkTo, text, style }) => {
  return (
    <div>
      <div>
        <Link to={linkTo}  style={{ textDecoration: 'none',  ...style }}>
          <i className={iconClass} />
        </Link>
     </div>
     </div>
  );
};

export default IconLink;
