import { createSlice } from '@reduxjs/toolkit';
import plansData from '../config/planData.json'; // Adjust the path as necessary

interface Feature {
    text: string;
    included: boolean;
}

export interface Plan {
    title: string;
    subtitle: string;
    priceMonthly: number;
    priceAnnual: number;
    features: Feature[];
    badgeText?: string;
    isPopular?: boolean;
    description: string;
    iconClass: string;
}

interface PlanState {
    plans: Plan[];
}

const initialState: PlanState = {
    plans: plansData as Plan[] // Use imported JSON data with type assertion
};

const planSlice = createSlice({
    name: 'plans',
    initialState,
    reducers: {},
});

export default planSlice.reducer;
