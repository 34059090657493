// src/components/ServiceCard.tsx
import React from 'react';
import Icon from '../atoms/icon'; 
import Paragraph from '../atoms/paragraph'; 
import Heading from '../atoms/heading';

interface ServiceCardProps {
  iconClass: string;
  title: string;
  description: string;
  link: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ iconClass, title, description, link }) => {
  return (
    <div className="d-flex p-3">
      <div className="flex-shrink-0 me-3 ">
        <Icon iconClass={iconClass} className='icon-effect' small={true} /> 
      </div>
      <div className="flex-grow-1">
      <Heading className="fs-18" level={5}>{title}</Heading>
        
        <Paragraph className="text-muted my-3">{description}</Paragraph>
        <a href={link} className="fs-13 fw-medium">
          Learn More <i className="ri-arrow-right-s-line align-bottom"></i>
        </a>
      </div>
    </div>
  );
};

export default ServiceCard;
