import React from "react";
import { Link } from "react-router-dom";

interface CombinedIconProps {
  className?: string;
  iconClass: string;
  linkTo?: string;
  small?: boolean;
  withEffect?: boolean;
}

const Icon: React.FC<CombinedIconProps> = ({
  className = "",
  iconClass,
  linkTo,
  small = false,
  withEffect = false,
}) => {
  const avatarClass = small ? "avatar-xs" : "avatar-sm";
  const effectClass = withEffect ? "icon-effect" : "";
  const titleClass = `avatar-title bg-transparent rounded-circle ${
    small ? "h2" : ""
  }`;

  const iconElement = (
    <div className={`${avatarClass} ${effectClass} ${className}`}>
      <div className={titleClass}>
        <i className={iconClass}></i>
      </div>
    </div>
  );

  if (linkTo) {
    return (
      <Link to={linkTo}>
        {iconElement}
      </Link>
    );
  }

  return iconElement;
};

export default Icon;
