// src/components/organisms/FeaturesSection.tsx
import React from 'react';
import FeatureCard from '../molecules/featureCard';
import FeatureHighlight from '../molecules/featureHighlight';
import FeatureDetails from '../molecules/featuresDetails';

interface Feature {
  heading: string;
  imgSrc: string;
  imgAlt: string;
  title: string;
  description: string;
  iconClass: string;
  details: string[];
}

interface FeaturesSectionProps {
  features: Feature[];
}

const FeaturesSection: React.FC<FeaturesSectionProps> = ({ features }) => {
  if (features.length < 3) {
    return <p>Not enough features data available</p>;
  }
  console.log(features[0].imgSrc)

  return (
    <>
     <section className="section bg-light py-5" id="features">
      <FeatureCard
        imgSrc={features[0].imgSrc}
        imgAlt={features[0].imgAlt}
        title={features[0].title}
        description={features[0].description}
        iconClass={features[0].iconClass}
        statistics={{
          dashboards: 5,
          pages: '150+',
          functionalApps: '7+',
        }}
      />
      <FeatureHighlight />
      <FeatureDetails
        imgSrc={features[1].imgSrc}
        imgAlt={features[1].imgAlt}
        title={features[1].title}
        heading={features[1].heading}
        description={features[1].description}
        details={features[1].details}
        imagePosition="left"
      />
      <FeatureHighlight />
      <FeatureDetails
        imgSrc={features[2].imgSrc}
        imgAlt={features[2].imgAlt}
        title={features[2].title}
        heading = {features[2].heading}
        description={features[2].description}
        details={features[2].details}
        imagePosition="right"
      />
      </section>
    </>
  );
};

export default FeaturesSection;
