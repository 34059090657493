import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ContactForm from '../molecules/contactForm';
import Heading from '../atoms/heading'; 
import Paragraph from '../atoms/paragraph';


interface ContactProps {
    officeAddresses: {
        address1: string;
        address2: string;
    };
    workingHours: string;
}

const Contact: React.FC<ContactProps> = ({ officeAddresses, workingHours }) => {
    return (
        <section className="section" id="contact">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <div className="text-center mb-5">
                        <Heading level={3} className="mb-3 fw-semibold" >Get In Touch</Heading>
                            
                            {/* Dynamic description */}
                            <Paragraph className="text-muted mb-4 ff-secondary">
                                We thrive when coming up with innovative ideas but also understand that a smart concept should be supported with measurable results.
                            </Paragraph>
                        </div>
                    </Col>
                </Row>

                <Row className="gy-4">
                    <Col lg={4}>
                        <div>
                            {/* Dynamic office addresses */}
                            <div className="mt-4">
                                
                            <Heading level={5} className="fs-13 text-muted text-uppercase" >Office Address 1:</Heading>
                             
                                <div className="fw-semibold">{officeAddresses.address1}</div>
                            </div>
                            <div className="mt-4">
                            <Heading level={5} className="fs-13 text-muted text-uppercase" >Call Us/Whatsapp</Heading>
        
                                <div className="fw-semibold">{officeAddresses.address2}</div>
                            </div>
                            {/* Dynamic working hours */}
                            <div className="mt-4">
                                
                            <Heading level={5} className="fs-13 text-muted text-uppercase" >Email Address</Heading>
                                <div className="fw-semibold">{workingHours}</div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={8}>
                        {/* Render ContactForm component */}
                        <ContactForm />
                    </Col>
                </Row>
            </Container>
           
        </section>
    );
};

export default Contact;
