import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import footerData from '../config/footerData.json'; // Adjust the path as needed

// Define types based on the JSON structure
interface FooterLink {
  to: string;
  text: string;
}

interface IconLink {
  iconClass: string;
  linkTo: string;
  text: string;
}

interface FooterState {
  title: string;
  heading: string;
  addressHeading: string;
  addressDetails: string;
  description1: string;
  description2: string;
  description3: string;
  links: FooterLink[];
  iconLinks: IconLink[];
}

// Initialize the state with the imported JSON data
const initialState: FooterState = footerData as FooterState;

const footerSlice = createSlice({
  name: 'footer',
  initialState,
  reducers: {
    setFooterData: (state, action: PayloadAction<Partial<FooterState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setFooterData } = footerSlice.actions;

export default footerSlice.reducer;
