import React from 'react'
import Landingpage from "./components/pages/landingPage";
import './assets/scss/themes.scss';



function App() {
  return (
    
     
        <Landingpage />
    
  );
}

export default App;
