// src/slices/teamSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import TeamData from '../config/teamMemberData.json';

// Define the interface for team member
interface TeamMember {
    avatarSrc: string;
    profileLink: string;
    mailLink: string;
    name: string;
    position: string;
}

// Define the interface for team state
interface TeamState {
    teamHeading: string;
    teamHeading2: string;
    description: string;
    teamMembers: TeamMember[];
}

// Initialize the state with default values
const initialState: TeamState = {
    teamHeading: TeamData.teamHeading,
    teamHeading2: TeamData.teamHeading2,
    description: TeamData.description,
    teamMembers: TeamData.teamMembers
};

// Create the slice for team data
const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        setTeamData(state, action: PayloadAction<Partial<TeamState>>) {
            // Update only the fields provided in the action payload
            return { ...state, ...action.payload };
        }
    }
});

// Export the action creator and reducer
export const { setTeamData } = teamSlice.actions;
export default teamSlice.reducer;
