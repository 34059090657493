import React from "react";
import { NavLink } from "react-router-dom";

interface NavLinkItemProps {
  href: string;
  text: string;
  onClick: () => void;
  activeLink: string | null;
}

const NavLinkItem: React.FC<NavLinkItemProps> = ({ href, text, onClick, activeLink }) => (
  <li className={`nav-item ${activeLink === href ? "active" : ""}`}>
    <NavLink to={href} className="nav-link fs-15 fw-semibold" onClick={onClick}>
      {text}
    </NavLink>
  </li>
);

export default NavLinkItem;
