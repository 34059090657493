import { combineReducers } from '@reduxjs/toolkit';
import heroReducer from './heroSlice';
import servicesReducer from './serviceSlice';
import featureReducer from './featureSlice';
import planReducer from './planSlice';
import contactReducer from './contactSlice';
import footerReducer from './footerSlice';
import teamReducer from "./teamSlice";

const rootReducer = combineReducers({
  hero: heroReducer,
  services: servicesReducer,
  features: featureReducer,
  planSlice: planReducer,
  contactSlice: contactReducer,
  footer: footerReducer,
  team: teamReducer,
});

export type RootState = ReturnType<typeof rootReducer>; // Ensure RootState reflects the combined reducers

export default rootReducer;
