import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { EffectFade, Autoplay } from "swiper/modules";
import CarouselSlide from '../atoms/carouselSlide';

interface HeroCarouselProps {
  slides: { src: string; alt: string; }[];
}

const HeroCarousel: React.FC<HeroCarouselProps> = ({ slides }) => {
  return (
    <Swiper
      spaceBetween={30}
      effect={"fade"}
      loop={true}
      pagination={{ clickable: true }}
      autoplay={{ delay: 2000, disableOnInteraction: false }}
      modules={[EffectFade, Autoplay]}
      className="mySwiper"
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <CarouselSlide src={slide.src} alt={slide.alt} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HeroCarousel;
