// src/components/molecules/TeamMemberCard.tsx
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Image from '../atoms/image';
import Icon from '../atoms/icon';
import LinkAtom from '../atoms/link';


interface TeamMemberCardProps {
    avatarSrc: string;
    mailLink: string;
    name: string;
    profileLink: string;
    position: string;
}

const TeamMemberCard: React.FC<TeamMemberCardProps> = ({ avatarSrc,  mailLink, name, position, profileLink }) => {
    return (
        <Card>
            <CardBody className="text-center p-4">
            <div className="avatar-xl mx-auto mb-4 position-relative">
                    <Image src={avatarSrc} alt={name} className="img-fluid rounded-circle" />
                    
                    <Icon iconClass="ri-mail-fill align-bottom" className="btn btn-success btn-sm position-absolute bottom-0 end-0 rounded-circle avatar-xs"
                     linkTo={mailLink}  />
                    
                </div>
                <h5 className="mb-1">
                    <LinkAtom to={profileLink}
                    customClassName="text-body"
                    >{name}</LinkAtom>
                </h5>
                
                <p className="text-muted mb-0 ff-secondary">{position}</p>
        
            </CardBody>
        </Card>
    );
};

export default TeamMemberCard;
