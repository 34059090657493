import React from "react";
import { Col, Container, Row } from 'reactstrap';
import HeroContent from '../molecules/heroContent'; 
import HeroCarousel from "../molecules/heroCarousel";
import Image from "../atoms/image";





import imgpattern from "../../../assets/images/landing/img-pattern.png";


interface HeroSectionProps {
  heroHeading: string;
  logoText: string;
  description: string;
  buttons: { to: string; color: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark"; text: string; }[];
  carouselSlides: { src: string; alt: string; }[];
}

const HeroSection: React.FC<HeroSectionProps> = ({ heroHeading, logoText, description, buttons, carouselSlides }) => {
  return (
    <section className="section pb-0 hero-section" id="hero">
      <div className="bg-overlay bg-overlay-pattern"></div>
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} sm={10}>
          <div className="text-center mt-lg-5 pt-5">
            <HeroContent
              heroHeading={heroHeading}
              logoText={logoText}
              description={description}
              buttons={buttons}
            />
            <div className="mt-4 mt-sm-5 pt-sm-5 mb-sm-n5 demo-carousel">
            <div className="demo-img-patten-top d-none d-sm-block">
            
                  <Image src={imgpattern} className="d-block img-fluid" alt="Pattern Top" />
                </div>
                <div className="demo-img-patten-bottom d-none d-sm-block">
                  <Image src={imgpattern} className="d-block img-fluid" alt="Pattern Bottom" />
                </div>
              
              {/* <img src="../../../assets/images/landing/img-pattern.png" className="d-block img-fluid" alt="..."/> */}
        
              
                <HeroCarousel slides={carouselSlides} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
