import React from "react";
import NavLinkItem from "../atoms/navLinkItem";

interface NavLinksProps {
  onNavLinkClick: (href: string) => void;
  activeLink: string | null;
}

const NavLinks: React.FC<NavLinksProps> = ({ onNavLinkClick, activeLink }) => {
  const handleClick = (href: string) => {
    onNavLinkClick(href);
  };

  return (
    <ul className="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example">
      <NavLinkItem
        href="#hero"
        text="Home"
        onClick={() => handleClick("#hero")}
        activeLink={activeLink}
      />
      <NavLinkItem
        href="#services"
        text="Services"
        onClick={() => handleClick("#services")}
        activeLink={activeLink}
      />
      <NavLinkItem
        href="#features"
        text="Features"
        onClick={() => handleClick("#features")}
        activeLink={activeLink}
      />
      <NavLinkItem
        href="#plans"
        text="Plans"
        onClick={() => handleClick("#plans")}
        activeLink={activeLink}
      />
      <NavLinkItem
        href="#reviews"
        text="Reviews"
        onClick={() => handleClick("#reviews")}
        activeLink={activeLink}
      />
      <NavLinkItem
        href="#team"
        text="Team"
        onClick={() => handleClick("#team")}
        activeLink={activeLink}
      />
      <NavLinkItem
        href="#contact"
        text="Contact"
        onClick={() => handleClick("#contact")}
        activeLink={activeLink}
      />
    </ul>
  );
};

export default NavLinks;
