import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ServiceCard from '../molecules/serviceCard';
import Heading from '../atoms/heading';


interface Service {
  iconClass: string;
  title: string;
  description: string;
  link: string;
}

interface ServicesSectionProps {
  services: Service[];
}

const ServicesSection: React.FC<ServicesSectionProps> = ({ services }) => {
  return (
    <section className="section" id="services">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
          <div className="text-center mb-5">
          <Heading level={1} className="mb-3 ff-secondary fw-bold lh-base">
          A Digital web design studio creating modern & engaging online
              </Heading>
              </div>
          </Col>
        </Row>

        <Row className="g-3">
          {services.map((service, index) => (
            <Col lg={4} key={index}>
              <ServiceCard
                iconClass={service.iconClass}
                title={service.title}
                description={service.description}
                link={service.link}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ServicesSection;
