import React from 'react';
import { Button as BootstrapButton, ButtonProps } from 'react-bootstrap';
import { Link, LinkProps } from 'react-router-dom';

interface CustomButtonProps extends ButtonProps {
  variant?: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" | "link";
  size?: "sm" | "lg";
  outline?: boolean;
  block?: boolean;
  active?: boolean;
  disabled?: boolean;
  customClassName?: string;
  to?: LinkProps['to'];
}

const Button: React.FC<CustomButtonProps> = ({
  children,
  variant = "primary",
  size,
  outline = false,
  block = false,
  active = false,
  disabled = false,
  customClassName,
  className,
  to,
  ...props
}) => {
  const classes = [
    "btn",
    outline ? `btn-outline-${variant}` : `btn-${variant}`,
    size ? `btn-${size}` : "",
    block ? "btn-block" : "",
    active ? "active" : "",
    className || "",
    customClassName || "",
  ].filter(Boolean).join(" ");

  const linkProps: LinkProps = {
    className: classes,
    to: to as LinkProps['to']
  };

  const buttonProps = {
    className: classes,
    disabled,
    ...props
  };

  if (to) {
    return (
      <Link {...linkProps}>
        {children}
      </Link>
    );
  }

  return (
    <BootstrapButton {...buttonProps}>
      {children}
    </BootstrapButton>
  );
};

export default Button;
