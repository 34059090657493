import React from 'react';
import Image from './image';

interface CarouselSlideProps {
  src: string;
  alt: string;
}

const CarouselSlide: React.FC<CarouselSlideProps> = ({ src, alt }) => {
  return (
    <div className="carousel-inner shadow-lg p-2 bg-white rounded">
      <Image src={src} alt={alt} />
    </div>
  );
};

export default CarouselSlide;
