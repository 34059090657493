import React from 'react';
import Button from '../atoms/button';
import HeaderSection from '../atoms/headerSection';

interface HeroContentProps {
  heroHeading: string;
  logoText: string;
  description: string;
  buttons: { to: string; color: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark"; text: string; }[];
}

const HeroContent: React.FC<HeroContentProps> = ({ heroHeading, logoText, description, buttons }) => {
  return (
    <>
      <HeaderSection
        heroHeading={heroHeading}
        logoText={logoText}
        description={description}
        className="mb-4"
      />
      <div className="d-flex gap-2 justify-content-center mt-4">
        
        {buttons.map((button, index) => (
          <Button key={index} to={button.to} variant={button.color}>
            {button.text} <i className={`ri-${button.color === 'primary' ? 'arrow-right-line' : 'eye-line'} align-middle ms-1`}></i>
          </Button>
        ))}
      </div>
    </>
  );
};

export default HeroContent;
