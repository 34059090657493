import React from "react";
import Logo from "../atoms/logo";
import Paragraph from "../atoms/paragraph";
import Link from "../atoms/link";
import { Row, Col } from "reactstrap";
import IconLink from "../atoms/iconLink"; 
import Heading from "../atoms/heading";

interface FooterSectionProps {
  title: string;
  heading: string;
  addressHeading: string;
  addressDetails: string;
  description1: string;
  description2: string;
  description3: string;
  links: { to: string; text: string }[];
  iconLinks: { iconClass: string; linkTo: string; text: string }[];
}

const FooterSection: React.FC<FooterSectionProps> = ({ title, heading, links, iconLinks, description1, description2, description3, addressHeading, addressDetails }) => {
  return (
    <>
      <Row>
        <Col lg={4} className="mt-4">
          <div>
            <div>
              <Logo />
            </div>
            <div className="mt-4">
              <Paragraph>{description1}</Paragraph>
              <Paragraph className="ff-secondary">{description2}</Paragraph>
            </div>
          </div>
          
        </Col>
        

        <Col lg={7} className="ms-lg-auto">
          <Row>
            <Col sm={4} className="mt-4">
            <Heading level={5} className="text-white mb-0" >{heading}</Heading>
              
              <div className="text-muted mt-3">
                <ul className="list-unstyled ff-secondary footer-list">
                  {links.map((link, index) => (
                    <li key={index}>
                      <Link to={link.to} >{link.text}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col sm={4} className="mt-4">
        <Heading level={5} className="text-white mb-0" >{addressHeading}</Heading>
        
        <Paragraph className="list-unstyled ff-secondary footer-list">{addressDetails}</Paragraph>
        
        </Col>
          </Row>
        </Col>
      </Row>
      
      

      <Row className="text-center text-sm-start align-items-center mt-5">
        <Col sm={6}>
          <div>
            <Paragraph className="copy-rights mb-0">{description3}</Paragraph>
          </div>
        </Col>
        <Col sm={6}>
          <div className="text-sm-end mt-3 mt-sm-0">
            <ul className="list-inline mb-0 footer-social-link">
              {iconLinks.map((iconLink, index) => (
                <li key={index} className="list-inline-item">
              
                    <IconLink  iconClass={iconLink.iconClass} linkTo={iconLink.linkTo} text={iconLink.text} />
          
                </li>
              ))}
            </ul>
          </div>
        </Col>
        </Row>
        
      
    </>
  );
};

export default FooterSection;
