import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../Store/store';
import LandingTemp from '../Templates/landingTemp';

const Index: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { heroHeading, logoText, description, buttons, carouselSlides } = useSelector((state: RootState) => state.hero);
  const { services } = useSelector((state: RootState) => state.services);
  const { widgets } = useSelector((state: RootState) => state.features);
  const { plans } = useSelector((state: RootState) => state.planSlice);
  const { addresses, workingHours } = useSelector((state: RootState) => state.contactSlice);
  const { teamHeading, teamHeading2, description: teamDescription, teamMembers } = useSelector((state: RootState) => state.team);
  const { title, heading, addressDetails, addressHeading, description1, description2, description3, links, iconLinks } = useSelector((state: RootState) => state.footer);
  
  

  const contactData = {
    officeAddresses: {
      address1: addresses[0]?.address || '', // Ensure it defaults to an empty string if address is missing
      address2: addresses[1]?.address || '',
    },
    workingHours: workingHours,
  };

  const footerData = {
    title,
    addressDetails,
    addressHeading,
    heading,
    description1,
    description2,
    description3,
    links,
    iconLinks,
  };

  const teamData = {
    teamHeading,
    teamHeading2,
    description: teamDescription,
    teamMembers,
  };


  
  console.log('teamData:', teamData);
  return (
    <div className="layout-wrapper landing">
    <LandingTemp
      heroHeading={heroHeading}
      logoText={logoText}
      description={description}
      buttons={buttons}
      services={services}
      featuresData={{ heading: '', description: '', widgets }}
      plans={plans}
      contactData={contactData}
      footerData={footerData}
      teamData={teamData} 
      carouselSlides={carouselSlides}
    />
       <button 
        className="btn btn-danger btn-icon landing-back-top" 
        id="back-to-top" 
        style={{ display: 'block', position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}
      >
        <i className="ri-arrow-up-line"></i>
      </button>
    </div>
  );
};

export default Index;
