import React from "react";
import HeroSection from "../UI/organisms/heroSection"; // Adjust import path as per your project structure
import ServicesSection from "../UI/organisms/serviceSection"; // Adjust import path as per your project structure
import Navbar from "../UI/organisms/navbar"; // Adjust import path as per your project structure
import PlanSection from "../UI/organisms/plan"; // Adjust import path as per your project structure
import FeaturesSection from "../UI/organisms/feature"; // Adjust import path as per your project structure
import Contact from "../UI/organisms/contact"; // Adjust import path as per your project structure
import Footer from "../UI/organisms/footer";
import TeamSection from "../UI/organisms/teamMembar"; // Adjust import path as per your project structure
import CarouselSlide from "../UI/atoms/carouselSlide";

interface ButtonData {
  to: string;
  color: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark";
  text: string;
}

interface Service {
  iconClass: string;
  title: string;
  description: string;
  link: string;
}

interface FeatureWidget {
  imgSrc: string;
  imgAlt: string;
  heading: string;
  title: string;
  description: string;
  iconClass: string;
  details: string[];
}

interface Feature {
  heading: string;
  description: string;
  imgSrc: string;
  imgAlt: string;
  title: string;
  iconClass: string;
  details: string[];
}

interface FeatureData {
  heading: string;
  description: string;
  widgets: FeatureWidget[];
}


interface Plan {
  title: string;
  subtitle: string;
  priceMonthly: number;
  priceAnnual: number;
  features: { text: string; included: boolean }[];
  badgeText?: string;
  isPopular?: boolean;
  description: string;
  iconClass: string;
}

interface ContactData {
  officeAddresses: {
    address1: string;
    address2: string;
  };
  workingHours: string;
}

interface FooterLink {
  to: string;
  text: string;
}

interface IconLink {
  iconClass: string;
  linkTo: string;
  text: string;
}

interface FooterData {
  addressHeading: string;
  addressDetails: string;
  title: string;
  heading: string;
  description1: string;
  description2: string;
  description3: string;
  links: FooterLink[];
  iconLinks: IconLink[];
}

interface TeamData {
  teamHeading: string;
  teamHeading2: string;
  description: string;
  teamMembers: TeamMember[];
}

interface TeamMember {
  avatarSrc: string;
  mailLink: string;
  name: string;
  position: string;
  profileLink: string;
 
}

interface LandingTempProps {
  heroHeading: string;
  logoText: string;
  description: string;
  buttons: ButtonData[];
  services: Service[];
  featuresData: FeatureData;
  plans: Plan[];
  contactData: ContactData;
  footerData: FooterData;
  teamData: TeamData; 
  carouselSlides: any[];  
  
}

const LandingTemp: React.FC<LandingTempProps> = ({
  heroHeading,
  logoText,
  description,
  buttons,
  services,
  featuresData,
  plans,
  contactData,
  teamData,
  footerData,
  carouselSlides,
  
}) => {
  const handleNavLinkClick = (href: string) => {
    // Handle navigation link click actions
    console.log("Navigating to:", href);
  };

  const featuresWithDetails = featuresData.widgets.map((widget) => ({
    ...widget,
    details: widget.details || [] // Add details property if it doesn't exist
  }));

  return (
    <div >
      <Navbar onNavLinkClick={handleNavLinkClick} activeLink="home" /> {/* Pass appropriate activeLink prop */}
      <HeroSection heroHeading={heroHeading} logoText={logoText} description={description} buttons={buttons}  carouselSlides={carouselSlides}   />
      <ServicesSection services={services} />
      <FeaturesSection features={featuresData.widgets} />{/* Use featuresWithDetails */}
      <PlanSection plans={plans} />
      <Contact officeAddresses={contactData.officeAddresses} workingHours={contactData.workingHours} />
      <TeamSection
       teamHeading={teamData.teamHeading}
       teamHeading2={teamData.teamHeading2}
        description={teamData.description}
        teamMembers={teamData.teamMembers}
      />
      <Footer
      addressHeading={footerData.addressHeading}
      addressDetails={footerData.addressDetails}
        title={footerData.title}
        heading={footerData.heading}
        description1={footerData.description1}
        description2={footerData.description2}
        description3={footerData.description3}
        links={footerData.links}
        iconLinks={footerData.iconLinks}
      /> {/* Pass the footerData to the Footer component */}
    </div>
  );
};

export default LandingTemp;
