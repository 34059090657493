import React from 'react';

interface CaptchaProps {
  onChange: (value: string | null) => void;
}

const Captcha: React.FC<CaptchaProps> = ({ onChange }) => {
  // Simulate a successful reCAPTCHA verification
  const handleVerify = () => {
    const simulatedToken = 'mock-token'; // Simulated token
    onChange(simulatedToken);
  };

  return (
    <button type="button" onClick={handleVerify} className="btn btn-secondary">
      Simulate reCAPTCHA Verification
    </button>
  );
};

export default Captcha;
