// src/components/UI/organisms/TeamSection.tsx
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import TeamMemberCard from '../molecules/TeamMemberCard';
import Heading from '../atoms/heading';
import Paragraph from '../atoms/paragraph';

interface TeamMemberCardProps {
    avatarSrc: string;
    profileLink: string;
    mailLink: string;
    name: string;
    position: string;
}

interface TeamSectionProps {
    teamHeading: string;
    teamHeading2: string;
    description: string;
    teamMembers: TeamMemberCardProps[];
}

const TeamSection: React.FC<TeamSectionProps> = ({ teamHeading, teamHeading2, description, teamMembers }) => {


    return (
        <section className="section bg-light" id="team">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <div className="text-center mb-5">
                            <Heading level={3} className="mb-3 fw-semibold">
                                {teamHeading} <span className="text-danger">{teamHeading2}</span>
                            </Heading>
                            <Paragraph className="text-muted mb-4 ff-secondary">
                                {description}
                            </Paragraph>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {teamMembers.map((member, index) => (
                        <Col lg={3} sm={6} key={index}>
                            <TeamMemberCard {...member} />
                        </Col>
                    ))}
                </Row>
                <Row>
                    <div className="col-lg-12">
                        {/* <div className="text-center mt-2">
                            <Link to="/pages-team" className="btn btn-primary">
                                View All Members <i className="ri-arrow-right-line ms-1 align-bottom"></i>
                            </Link>
                        </div> */}
                    </div>
                </Row>
            </Container>
        </section>
    );
};

export default TeamSection;
