// src/redux/featureSlice.ts
import { createSlice } from '@reduxjs/toolkit';
import FeaturesData from '../config/featuresData.json';

export interface FeatureWidget {
  imgSrc: string;
  imgAlt: string;
  heading: string;
  title: string;
  description: string;
  iconClass: string;
  details: string[];
}

interface FeatureState {
  widgets: FeatureWidget[];
}

// Ensure all properties in FeaturesData are defined and not undefined
const validateFeaturesData = (data: any): FeatureWidget[] => {
  return data.map((item: any) => ({
    imgSrc: item.imgSrc || '',
    imgAlt: item.imgAlt || '',
    heading: item.heading || '',
    title: item.title || '',
    description: item.description || '',
    iconClass: item.iconClass || '',
    details: item.details || []
  }));
};

const initialState: FeatureState = {
  widgets: validateFeaturesData(FeaturesData)
};

const featureSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {}
});

export default featureSlice.reducer;
