import { createSlice } from '@reduxjs/toolkit'
import contactData from "../config/contactData.json";

interface Address {
  type: string;
  address: string;
}

interface ContactState {
  heading: string;
  subHeading: string;
  addresses: Address[];
  workingHours: string;
}

const initialState: ContactState = contactData as ContactState;
  

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {},
});

export default contactSlice.reducer;
