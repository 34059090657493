import React from 'react';
import { Link } from 'react-router-dom';
import logodark from "../../../assets/images/logo-sm-1.png";
import logolight from "../../../assets/images/logo-sm-1.png";

const NavbarBrand: React.FC = () => {
  return (
    <Link className="navbar-brand" to="/index">
      <img src={logodark} className="card-logo card-logo-dark" alt="logo dark" height="17" />
      <img src={logolight} className="card-logo card-logo-light" alt="logo light" height="17" />
    </Link>
  );
};

export default NavbarBrand;
