import React from "react";
import { Link } from "react-router-dom";

interface LinkProps {
  to: string;
  children: React.ReactNode;
  customClassName?: string;
  style?: React.CSSProperties;
}

const LinkAtom: React.FC<LinkProps> = ({ to, children, customClassName, style }) => {
  return (
    <Link to={to} className={customClassName}
    style={{ textDecoration: 'none',  ...style }}
    >
      {children}
    </Link>
  );
};

export default LinkAtom;
export{};