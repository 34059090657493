// src/components/UI/atoms/InputField.tsx
import React from 'react';

interface InputFieldProps {
  label: string;
  id: string;
  type: string;
  name: string;
  placeholder: string;
  value: string; // Add the value prop
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void; // Add the onChange prop
}

const InputField: React.FC<InputFieldProps> = ({ label, id, type, name, placeholder, value, onChange }) => {
  return (
    <div className="mb-4">
      <label htmlFor={id} className="form-label fs-13">{label}</label>
      <input
        type={type}
        className="form-control bg-light border-light"
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default InputField;
