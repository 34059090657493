// components/HeaderSection.tsx
import React from 'react';


interface HeaderSectionProps {
  heroHeading?: string;
  logoText?:string;
  heading?: string;
  description?: string;
  className?: string;
}

const HeaderSection: React.FC<HeaderSectionProps> = ({ heroHeading,logoText, heading, description, className }) => {
  return (
    <div className="text-center mt-lg-5 pt-5">
      {(heroHeading || logoText) && (
      <h1 className="display-6 fw-bold mb-3 lh-base">
        {heroHeading} <span className="text-success">{logoText}</span>
      </h1>
    )}
      {heading && <h2>{heading} </h2> }
      {description && <p className="lead text-muted lh-base">{description}</p>}
    </div>
  );
};

export default HeaderSection;
