import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Icon from '../atoms/icon';
import Heading from '../atoms/heading';

interface FeatureDetailsProps {
  heading: string; 
  imgSrc: string;
  imgAlt: string;
  title: string;
  description: string;
  details: string[];
  buttonText?: string;
  buttonLink?: string;
  imagePosition?: 'left' | 'right';
}

const FeatureDetails: React.FC<FeatureDetailsProps> = ({
  imgSrc,
  imgAlt,
  heading,
  title,
  description,
  details,
  buttonText = "Learn More",
  buttonLink = "/index",
  imagePosition = 'left'
}) => {
  // Split details into two arrays: first 3 details and the rest
  const firstColumnDetails = details.slice(0, 3);
  const secondColumnDetails = details.slice(3);

  return (
    <section className="section">
      <Container>
        <Row className="align-items-center gy-4">
          {imagePosition === 'left' ? (
            <>
              <Col lg={6} className="order-2 order-lg-1">
                <div className="text-muted">
                  <Heading level={3} className="fs-12 text-uppercase text-success">{heading}</Heading>
                  <Heading level={4} className="mb-3">{title}</Heading>
                  <p className="mb-4">{description}</p>
                  <Row>
                    <Col sm={6}>
                      <div className="vstack gap-2">
                        {firstColumnDetails.map((detail, index) => (
                          <div className="d-flex align-items-center text-success" key={index}>
                            <Icon iconClass="ri-check-fill" small={true} withEffect={true} className="me-2" />
                            <div className="flex-grow-1">
                              <h5 className="fs-14 mb-0">{detail}</h5>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="vstack gap-2">
                        {secondColumnDetails.map((detail, index) => (
                          <div className="d-flex align-items-center text-success" key={index}>
                            <Icon iconClass="ri-check-fill" small={true} withEffect={true} className="me-2" />
                            <div className="flex-grow-1">
                              <h5 className="fs-14 mb-0">{detail}</h5>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  {buttonText && buttonLink && (
                    <div className="mt-4">
                      <Link to={buttonLink} className="btn btn-primary">
                        {buttonText} <i className="ri-arrow-right-line align-middle ms-1"></i>
                      </Link>
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={6} sm={7} className="col-10 ms-auto order-1 order-lg-2">
                <div>
                  <img src={imgSrc} alt={imgAlt} className="img-fluid" />
                </div>
              </Col>
            </>
          ) : (
            <>
              <Col lg={6} sm={7} className="col-10 mx-auto">
                <div>
                  <img src={imgSrc} alt={imgAlt} className="img-fluid" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="text-muted ps-lg-5">
                  <Heading level={3} className="fs-12 text-uppercase text-success">{heading}</Heading>
                  <Heading level={4} className="mb-3">{title}</Heading>
                  <p className="mb-4">{description}</p>
                  <Row>
                    <Col sm={6}>
                      <div className="vstack gap-2">
                        {firstColumnDetails.map((detail, index) => (
                          <div className="d-flex align-items-center text-success" key={index}>
                            <Icon iconClass="ri-check-fill" small={true} withEffect={true} className="me-2" />
                            <div className="flex-grow-1">
                              <h5 className="fs-14 mb-0">{detail}</h5>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="vstack gap-2">
                        {secondColumnDetails.map((detail, index) => (
                          <div className="d-flex align-items-center text-success" key={index}>
                            <Icon iconClass="ri-check-fill" small={true} withEffect={true} className="me-2" />
                            <div className="flex-grow-1">
                              <h5 className="fs-14 mb-0">{detail}</h5>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  {buttonText && buttonLink && (
                    <div className="mt-4">
                      <Link to={buttonLink} className="btn btn-primary">
                        {buttonText} <i className="ri-arrow-right-line align-middle ms-1"></i>
                      </Link>
                    </div>
                  )}
                </div>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default FeatureDetails;
