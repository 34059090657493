import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Heading from '../atoms/heading';
import Switch from '../atoms/switch';
import PlanCard from '../molecules/planCard';
import Paragraph from '../atoms/paragraph';

interface PlanSectionProps {
  plans: {
    title: string;
    subtitle: string;
    priceMonthly: number;
    priceAnnual: number;
    features: { text: string; included: boolean }[];
    badgeText?: string;
    isPopular?: boolean;
    description: string;
    iconClass: string;
  }[];
}

const PlanSection: React.FC<PlanSectionProps> = ({ plans }) => {
  const [plan, setPlan] = useState(true);
  const toggle = () => setPlan(!plan);

  return (
    <section className="section bg-light" id="plans">
      <div className="bg-overlay bg-overlay-pattern"></div>
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="text-center mb-5">
              <Heading level={3} className="mb-3 fw-bold">
                Choose the plan that's right for you
              </Heading>
              {plans.length > 0 && (
                <Paragraph className="text-muted mb-4">
                  {plans[0].description}
                </Paragraph>
              )}
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  <Heading level={5} className="fs-14 mb-0">
                    Month
                  </Heading>
                </div>
                <Switch id="plan-switch" onChange={toggle} />
                <div>
                  <Heading level={5} className="fs-14 mb-0">
                    Annual <span className="badge bg-danger-subtle text-danger">Save 20%</span>
                  </Heading>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="gy-4">
          {plans.map((planData, index) => (
            <Col lg={4} key={index}>
              <Card className={`plan-box mb-0 ${planData.isPopular ? 'ribbon-box right' : ''}`}>
                <CardBody className="p-4 m-2">
                  <PlanCard
                    isPopular={planData.isPopular}
                    title={planData.title}
                    subtitle={planData.subtitle}
                    price={plan ? planData.priceMonthly : planData.priceAnnual}
                    priceUnit={plan ? '/Month' : '/Year'}
                    features={planData.features}
                    badgeText={planData.badgeText}
                    iconClass={planData.iconClass}
                  />
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default PlanSection;
