import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HeroData from '../config/heroData.json'; // Adjust the path to your config file

// Define types for button data
interface ButtonData {
  to: string;
  color: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark";
  text: string;
}

interface CarouselSlide {
  src: string;
  alt: string;
}

// Define the shape of the state
interface HeroState {
  heroHeading: string;
  logoText: string;
  description: string;
  buttons: ButtonData[];
  carouselSlides: CarouselSlide[];
}

// Initial state
const initialState: HeroState = HeroData as HeroState;

// Create a slice
const heroSlice = createSlice({
  name: 'hero',
  initialState,
  reducers: {
    setHeroHeading(state, action: PayloadAction<string>) {
      state.heroHeading = action.payload;
    },
    setLogoText(state, action: PayloadAction<string>) {
      state.logoText = action.payload;
    },
    setDescription(state, action: PayloadAction<string>) {
      state.description = action.payload;
    },
    setButtons(state, action: PayloadAction<ButtonData[]>) {
      state.buttons = action.payload;
    },
    setCarouselSlides(state, action: PayloadAction<CarouselSlide[]>) {
      state.carouselSlides = action.payload;
    },
  },
});

// Export actions and reducer
export const { setHeroHeading, setLogoText, setDescription, setButtons, setCarouselSlides } = heroSlice.actions;
export default heroSlice.reducer;
